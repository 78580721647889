import { useEffect, useState } from "react"
import { Button, Intent } from "@blueprintjs/core"
import { useRouter } from "next/router"

import { TransactionCard } from "."
import { useAcceptInvitation } from "features/transaction/id/api"
import useToaster from "helpers/toaster"
import { TransactionKind } from "models/Transaction"

type TransactionInvitationBrickProps = {
  transaction: string | null
  token: string
  transaction_name: string
  view?: "GRID" | "LIST"
  kind: TransactionKind
}

export const TransactionInvitationBrick = ({
  transaction: transactionId,
  token,
  transaction_name: name,
  view,
  kind,
}: TransactionInvitationBrickProps) => {
  const { failure } = useToaster()
  const { push } = useRouter()
  const [isLoadingAccept, setIsLoadingAccept] = useState(false)
  const {
    mutate: acceptInvitation,
    isLoading,
    error,
  } = useAcceptInvitation({
    onSuccess: () => {
      if (kind === TransactionKind.WIRES) {
        push("/projects/[id]", `/projects/${transactionId}`)
      } else {
        push("/transaction/[id]", `/transaction/${transactionId}`)
      }
    },
    onError: () => {
      failure("We were unable to accept the invitation. Please try again.")
    },
  })

  useEffect(() => {
    if (isLoading && !error) {
      setIsLoadingAccept(true)
    }
    if (error) {
      setIsLoadingAccept(false)
    }
  }, [isLoading, error])

  const handleInvitationAccept = async () => {
    if (token) {
      acceptInvitation(token)
    }
  }

  return (
    <>
      <TransactionCard key={token} view={view}>
        <TransactionCard.UnlinkedTitle text={name} />
        <TransactionCard.Body className="ml-auto">
          <Button
            rightIcon="confirm"
            intent={Intent.PRIMARY}
            loading={isLoadingAccept}
            onClick={handleInvitationAccept}
            fill={view === "GRID"}
          >
            Accept Invitation
          </Button>
        </TransactionCard.Body>
      </TransactionCard>
    </>
  )
}
