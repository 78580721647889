import { useAPI, usePaginatedAPI, useAPIMutation, ApiHttp } from "lib/api"
import {
  createTransactionParticipant,
  createTransaction,
  updateTransaction,
} from "helpers/api"
import {
  APITransaction,
  CreatableTransaction,
  UpdatableTransaction,
  TransactionSortOptions,
  encodeTransactionCreate,
  encodeTransactionUpdate,
} from "models/Transaction"
import { APIInvitation } from "models/Invitation"
import { SortDirectionType } from "components/menus/SortMenu"

export const TRANSACTION_PAGE_SIZE = 30

export function useAllTransactionsCount() {
  return useAPI(
    ["transactions", "all"],
    () => ApiHttp.get<{ count: number }>("/transactions/transaction?limit=1"),
    {
      select: (data) => data?.count || 0,
    }
  )
}

type APIData = {
  next: string | null
  results: APITransaction[]
}

export function useTransactions(
  searchTerm: string = "",
  filter: "active" | "archived" | "deactivated" = "active",
  orderName: TransactionSortOptions,
  orderDirection: SortDirectionType,
  limit = TRANSACTION_PAGE_SIZE
) {
  let order = orderDirection === "DESC" ? "-" : ""
  let orderValue = orderName === "sort" ? "" : `${order}${orderName}`

  if (
    orderName === "created_by__first_name,created_by__last_name" &&
    orderDirection === "DESC"
  ) {
    // Special case for created by since we need to pass both first and last name, but with - in front of both values
    orderValue = "-created_by__first_name,-created_by__last_name"
  }

  return usePaginatedAPI(
    ["transactions", "index", searchTerm, filter, orderName, orderDirection],
    ({ pageParam: nextUrl }: { pageParam?: null | string }) => {
      let params = new URLSearchParams(
        nextUrl?.split("?")[1] || {
          limit: String(limit),
          offset: String(0),
          search: searchTerm,
          archived: String(filter === "archived"),
          ordering: orderValue,
          expired: String(filter === "deactivated"),
        }
      )
      return ApiHttp.get<APIData>(
        `/transactions/transaction?${params.toString()}`
      )
    },
    {
      getNextPageParam: (result: APIData | null) => result?.next || undefined,
      select: (data) => ({
        pages: data?.pages.flatMap((page) => page?.results || []) || [],
        pageParams: data?.pageParams || [],
      }),
    }
  )
}

export function useTransactionInvitations() {
  return useAPI([`/invitations`], () =>
    ApiHttp.get<APIInvitation[]>("/invitations/invitation")
  )
}

export function useCreateTransaction(options?: any) {
  // TODO-TS: type options
  let data = useAPIMutation(
    (data: CreatableTransaction) =>
      createTransaction(encodeTransactionCreate(data)),
    options
  )

  return { ...data, createTransaction: data.mutateAsync }
}

export function useUpdateTransaction(options?: any) {
  let data = useAPIMutation(
    (data: UpdatableTransaction) =>
      updateTransaction(data.id, encodeTransactionUpdate(data)),
    options
  )

  return { ...data, updateTransaction: data.mutateAsync }
}

export function useCreateTransactionParticipant(options?: Record<string, any>) {
  let data = useAPIMutation(
    ({ transactionId, userId }: { transactionId: string; userId: string }) =>
      createTransactionParticipant({ transactionId, userId }),
    options
  )

  return { ...data, createTransactionParticipant: data.mutateAsync }
}
