import {
  Button,
  ButtonProps,
  Checkbox,
  FormGroup,
  InputGroup,
  Intent,
  TextArea,
} from "@blueprintjs/core"
import { ReactNode, useEffect } from "react"
import * as yup from "yup"
import { useForm } from "react-hook-form"

import { isEqualValues } from "helpers/object"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  type CreatableTransaction,
  type UpdatableTransaction,
  TransactionKind,
} from "models/Transaction"

const NEW_PROJECT_SCHEMA = yup.object({
  id: yup.string(),
  name: yup
    .string()
    .trim()
    .min(3, "A project name must be at least 3 characters.")
    .max(100, "A project name must be no more than 100 characters.")
    .required("A project name is required"),
  description: yup
    .string()
    .trim()
    .max(350, "Description must be no more than 350 characters."),
  isSample: yup.boolean(),
  clientName: yup.string().default(""),
  clientNumber: yup.string().default(""),
  matterNumber: yup.string().default(""),
  isDeedTransaction: yup.boolean(),
  kind: yup.string().oneOf(Object.values(TransactionKind)),
})

const CreateOrEditProjectForm = ({
  initialValues,
  buttons,
  submitButtonProps,
  submitButtonText = "Get started",
  onSubmit,
  isLoading,
}: {
  initialValues: UpdatableTransaction
  buttons?: ReactNode
  submitButtonProps?: ButtonProps
  submitButtonText?: string
  onSubmit: (values: CreatableTransaction, resetForm: () => void) => void
  isLoading?: boolean
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      id: initialValues.id ?? "",
      name: initialValues.name ?? "",
      description: initialValues.description ?? "",
      isSample: initialValues.isSample ?? false,
      clientName: initialValues.clientName ?? "",
      clientNumber: initialValues.clientNumber ?? "",
      matterNumber: initialValues.matterNumber ?? "",
      isDeedTransaction: initialValues.isDeedTransaction ?? false,
      kind: TransactionKind.WIRES,
    },
    resolver: yupResolver(NEW_PROJECT_SCHEMA),
  })

  function isProjectNameTest(str: string) {
    const projectName = str.toLowerCase()
    const testWords = ["demo", "test", "sample", "training", "example"]
    return testWords.some((testWord) => {
      const regex = new RegExp(`\\b${testWord}\\b`, "i")
      return regex.test(projectName)
    })
  }

  const watchedName = watch("name")
  const watchedDescription = watch("description")

  useEffect(() => {
    setValue("isSample", isProjectNameTest(watchedName))
  }, [watchedName])

  const { ref: nameRef, ...nameRefProps } = register("name", { required: true })
  const { ref: descriptionRef, ...descriptionRefProps } =
    register("description")
  const { ref: isSampleRef, ...isSampleRefProps } = register("isSample")

  const getIntent = (field: keyof typeof errors) =>
    errors[field] ? Intent.DANGER : Intent.NONE

  const projectNameHasValidName = watchedName.trim().length > 2

  return (
    <div>
      <form
        onSubmit={handleSubmit((values) => {
          onSubmit(values, reset)
        })}
      >
        <FormGroup
          className="mb-2 text-md"
          label="Project Name (required)"
          labelFor="name"
          helperText={errors.name?.message}
          intent={getIntent("name")}
        >
          <InputGroup
            data-1p-ignore
            inputRef={nameRef}
            id="name"
            fill
            intent={getIntent("name")}
            required
            autoFocus={true}
            {...nameRefProps}
          />
        </FormGroup>
        <FormGroup
          label="Description"
          labelFor="description"
          helperText={errors.description?.message}
          intent={getIntent("description")}
        >
          <TextArea
            inputRef={descriptionRef}
            id="description"
            rows={3}
            fill
            growVertically
            intent={getIntent("description")}
            disabled={!projectNameHasValidName}
            {...descriptionRefProps}
          />
        </FormGroup>

        <Checkbox
          id="isSample"
          inputRef={isSampleRef}
          className={initialValues.id !== "" ? "mb-2" : "reverse-checkbox mb-2"}
          label="Test project"
          {...isSampleRefProps}
        />

        <div className="flex justify-end">
          {buttons && buttons}
          <Button
            {...submitButtonProps}
            className="ml-3"
            type="submit"
            loading={isSubmitting || isLoading}
            disabled={
              isEqualValues(
                { name: "", description: "" },
                { name: watchedName, description: watchedDescription }
              ) || !projectNameHasValidName
            }
          >
            {submitButtonText}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default CreateOrEditProjectForm
