import { InputGroup } from "@blueprintjs/core"
import { AreYouSure } from "components/modals/AreYouSure"
import { TransactionKind } from "models/Transaction"

import { FormEvent, useState } from "react"

type TransactionDeleteModalProps = {
  transactionName?: string
  isOpen?: boolean
  onSubmit?: () => void
  onClose?: () => void
  kind?: string
}

export const TransactionDeleteModal = ({
  transactionName = "Transaction Name",
  isOpen,
  onSubmit,
  onClose,
  kind,
}: TransactionDeleteModalProps) => {
  const [fieldValue, setFieldValue] = useState("")

  const MATCHING_PHRASE = "DELETE"

  const isMatching = fieldValue.toUpperCase() === MATCHING_PHRASE

  const submit = () => {
    if (isMatching) {
      onSubmit?.()
    }
  }

  function handleSubmit(evt: FormEvent) {
    evt.preventDefault()
    submit()
  }

  return (
    <AreYouSure
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={submit}
      title={`Delete ${
        kind === TransactionKind.WIRES ? "project" : "transaction"
      }`}
      iconType="DANGER"
      disableConfirm={!isMatching}
    >
      <p className="mb-3">
        You are about to delete <strong>{transactionName}</strong>. All
        participants and signers will lose access. This action cannot be undone.
      </p>

      <form onSubmit={handleSubmit}>
        <label className="mb-1 block" htmlFor="confirm-delete">
          Type “DELETE” below to confirm.
        </label>
        <InputGroup
          id="confirm-delete"
          fill
          type="text"
          name="confirmationPhrase"
          value={fieldValue}
          onChange={(event) => setFieldValue(event.target.value)}
          autoFocus
        />
      </form>
    </AreYouSure>
  )
}
