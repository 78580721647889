import * as React from "react"

import { Button, Dialog, Intent } from "@blueprintjs/core"

type UpgradeAccountModalProps = {
  onClose?: () => void
}

export default function UpgradeAccountModal({
  onClose = () => null,
}: UpgradeAccountModalProps) {
  return (
    <Dialog onClose={onClose} isOpen className="w-112 rounded-none p-0">
      <div
        className="flex h-32 bg-cover bg-left"
        style={{ backgroundImage: `url('/static/Star background.png')` }}
      >
        <div className="color-white m-auto text-lg tracking-widest">
          Let’s upgrade your account!
        </div>
      </div>
      <div className="p-5">
        <p>
          Unfortunately it looks like you don’t have permission to create new
          transactions. <br />
          <br />
          If you are interested to learn how you or your law firm can license
          SimplyAgree, please visit{" "}
          <a href="https://simplyagree.com/contact">
            simplyagree.com/contact
          </a>{" "}
          to set up a time to learn more.
        </p>
        <div className="mt-5 flex">
          <Button
            className="ml-auto"
            text="Ok, got it"
            intent={Intent.PRIMARY}
            onClick={onClose}
          />
        </div>
      </div>
    </Dialog>
  )
}
