import { Button, Icon, Intent } from "@blueprintjs/core"

import TransactionForm from "components/forms/Transaction"
import SAModal from "components/modals/SAModal"
import { type UpdatableTransaction } from "models/Transaction"

type TransactionEditModalProps = {
  transaction: UpdatableTransaction
  isOpen?: boolean
  onSubmit: (t: UpdatableTransaction) => void
  onClose?: () => void
}

export const TransactionEditModal = ({
  transaction,
  isOpen,
  onSubmit,
  onClose,
}: TransactionEditModalProps) => (
  <SAModal
    title="Edit transaction"
    isOpen={isOpen}
    onClose={onClose}
    icon={<Icon icon="cog" size={22} />}
    body={
      <div className="w-[380px] px-5 py-4">
        <TransactionForm
          {...transaction}
          handleSubmit={onSubmit}
          buttons={
            <>
              <Button minimal onClick={() => onClose?.()}>
                <div className="text-blue-9">Cancel</div>
              </Button>
            </>
          }
          submitButtonProps={{
            intent: Intent.PRIMARY,
          }}
          submitButtonText="Save"
          disableWithoutEdit={false}
          hasRedHelperText
        />
      </div>
    }
  ></SAModal>
)
