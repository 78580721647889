import styled from "@emotion/styled"

import { Card, Button } from "@blueprintjs/core"

const Wrap = styled(Card)`
  background-image: linear-gradient(192deg, #5a829e 9%, #3c5d74 92%);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15);
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 137px;
  width: 268px;

  h3 {
    font-size: 22px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 1px;
    text-align: center;
    line-height: 21px;
    margin-bottom: 24px;
  }

  .bp4-button {
    background: rgba(255, 255, 255, 0.5);
    font-weight: 400;
    font-size: 14px;
    color: white;
    text-align: left;
    line-height: 18px;
  }
`

type WhatsNewBrickProps = {
  onClick?: () => void
}

export const WhatsNewBrick = ({ onClick }: WhatsNewBrickProps) => (
  <Wrap>
    <h3>What&apos;s New at SimplyAgree</h3>
    <Button onClick={onClick}>See what&apos;s new</Button>
  </Wrap>
)
