import { Icon } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

type TransactionDeactivatedLockIconProps = {
  expiredAt: string
  wrapperClassName?: string
}

export default function TransactionDeactivatedLockIcon({
  wrapperClassName,
  expiredAt,
}: TransactionDeactivatedLockIconProps) {
  return (
    <Tooltip2
      className={`${wrapperClassName} text-gray-500`}
      content={`Deactivated on ${new Intl.DateTimeFormat("en-US", {}).format(
        new Date(expiredAt)
      )}`}
      minimal
    >
      <Icon icon="lock" size={14} className="mb-0.5 text-gray-500" />
    </Tooltip2>
  )
}
