import { Button, Icon, Intent } from "@blueprintjs/core"

import CreateOrEditProjectForm from "features/projects/CreateOrEditProjectForm"
import SAModal from "components/modals/SAModal"
import { type UpdatableTransaction } from "models/Transaction"

type ProjectEditModalProps = {
  transaction: UpdatableTransaction
  isOpen?: boolean
  onClose?: () => void
  onSubmit: any
}

export const ProjectEditModal = ({
  transaction,
  isOpen,
  onClose,
  onSubmit,
}: ProjectEditModalProps) => {
  return (
    <SAModal
      title="Edit project"
      isOpen={isOpen}
      onClose={onClose}
      icon={<Icon icon="cog" size={22} />}
      body={
        <div className="w-[380px] px-5 py-4">
          <CreateOrEditProjectForm
            initialValues={transaction}
            buttons={
              <>
                <Button minimal onClick={() => onClose?.()}>
                  <div className="text-blue-9">Cancel</div>
                </Button>
              </>
            }
            submitButtonProps={{
              intent: Intent.PRIMARY,
            }}
            submitButtonText="Save"
            onSubmit={onSubmit}
          />
        </div>
      }
    ></SAModal>
  )
}
