import { useCurrentUser } from "features/auth/withAuth"

function useCurrentUserDMSName() {
  const { currentUser } = useCurrentUser()
  return currentUser?.dmsType === "NET_DOCS"
    ? "NetDocuments"
    : currentUser?.dmsType === "IMANAGE"
      ? "iManage"
      : "Your DMS"
}

export default useCurrentUserDMSName
