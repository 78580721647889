import styled from "@emotion/styled"
import { Dialog } from "@blueprintjs/core"

export const TransactionModal = styled(Dialog)`
  width: 400px;

  .wrapper {
    padding: 20px 20px 0 20px;
  }

  .content {
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows: min-content auto auto;
    grid-gap: 20px;

    .right {
      grid-column: 2/2;
    }

    .left {
      grid-column: 1/1;
    }
  }

  .actions {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
  }
`
