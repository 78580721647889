import { TransactionModal } from "./"

type WhatsNewModalProps = {
  isOpen?: boolean
  onClose?: () => void
}

export const WhatsNewModal = ({ isOpen, onClose }: WhatsNewModalProps) => (
  <TransactionModal
    isOpen={isOpen}
    title="News at SimplyAgree"
    onClose={onClose}
  >
    <div className="wrapper">
      <p>Nothing yet check back soon!</p>
    </div>
  </TransactionModal>
)
