import { ErrorClient } from "app/config/services/error-reporting"
import { ExternalHttp } from "lib/http"

const encode = ({
  email,
  full_name: name,
  organization_name: organization,
}) => ({
  addperson: true,
  email,
  delayminutes: 20160,
  name,
  organization,
})

export class Survey {
  constructor(user) {
    if (!user) throw "Invalid data for survey"

    this.user = user
  }

  async trigger() {
    let url = "/survey/trigger"

    try {
      await ExternalHttp.post(url, encode(this.user))
    } catch (e) {
      ErrorClient.notify(e)
    }
  }
}

export const NullSurvey = {
  trigger: async () => {
    console.info("No api key for survey trigger set...")
  },
}
