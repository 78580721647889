import { Button, Icon, InputGroup, Intent } from "@blueprintjs/core"
import { Classes, MenuItem2 as MenuItem } from "@blueprintjs/popover2"
import { Select2 as Select } from "@blueprintjs/select"
import { Dispatch, SetStateAction, useState } from "react"
import SortMenu, { SortDirectionType } from "components/menus/SortMenu"
import { TransactionSortOptions } from "models/Transaction"

type TransactionToggleViewProps = {
  view?: string
  setView: Dispatch<SetStateAction<"LIST" | "GRID">>
  orderDirection?: SortDirectionType
  setOrderDirection: Dispatch<SetStateAction<SortDirectionType>>
  orderName: TransactionSortOptions
  setOrderName: Dispatch<SetStateAction<TransactionSortOptions>>
  hideClientMatterNumberColumn?: boolean
}

export const TransactionToggleView = ({
  view = "GRID",
  setView,
  orderDirection,
  setOrderDirection,
  orderName,
  setOrderName,
  hideClientMatterNumberColumn,
}: TransactionToggleViewProps) => {
  const [orderNameToApply, setOrderNameToApply] =
    useState<TransactionSortOptions>(
      // if the orderName is "sort", we switch it to "created_at" to set a new default value for the
      // SortMenu so that it can be applied when the user clicks Apply button. "sort" is basically
      // `created_at` but with Last Viewed Transaction on top
      orderName === "sort" ? "created_at" : orderName
    )
  const [orderDirectionToApply, setOrderDirectionToApply] = useState(
    // set to DESC as a default fallback to mimic the default "Sort" orderName and direction if
    // no orderDirection is provided
    orderDirection ?? "DESC"
  )

  const handleApplyClick = () => {
    setOrderName(orderNameToApply)
    setOrderDirection(orderDirectionToApply)
  }

  const sortByOptions = [
    // "sort" is the default value coming from above, but we filter it out from options in the dropdown select
    { value: "sort", label: "Sort" },
    { value: "client_name", label: "Client name" },
    { value: "client_number", label: "Client/matter number" },
    { value: "created_at", label: "Created on" },
    {
      value: "created_by__first_name,created_by__last_name",
      label: "Created by",
    },
    { value: "name", label: "Transaction name" },
  ]

  const directionOptions = [
    { value: "ASC", label: "Ascending (A - Z)" },
    { value: "DESC", label: "Descending (Z - A)" },
  ]

  return (
    <div
      className="fixed top-[50px] z-20 grid w-full place-items-center bg-[#F5F8FA] pt-3"
      style={{ width: "calc(100% - 457px)" }}
    >
      <div className="flex w-full items-center justify-between px-12 pb-2">
        <div className="pl-[5px] flex items-center justify-start">
          {view === "LIST" && (
            <SortMenu
              tooltipContent="Sort transactions"
              sortType={orderDirection ?? null}
              onSortTypeChange={setOrderDirection}
            >
              <div className="p-4 w-[450px]">
                <label className="font-bold text-sm mb-2 block">Sort:</label>
                <div className="flex gap-4 mb-4">
                  <Select
                    items={sortByOptions.filter((i) => i.value !== "sort")}
                    itemRenderer={(item, { handleClick }) => (
                      <MenuItem
                        key={item.value}
                        text={item.label}
                        onClick={handleClick}
                        disabled={item.value === orderName}
                      />
                    )}
                    onItemSelect={(item) => {
                      setOrderNameToApply(
                        item.value as TransactionToggleViewProps["orderName"]
                      )
                    }}
                    filterable={false}
                    fill
                    popoverProps={{
                      minimal: true,
                      matchTargetWidth: true,
                      captureDismiss: true,
                    }}
                  >
                    <InputGroup
                      className="cursor-pointer"
                      readOnly
                      value={
                        sortByOptions.find(
                          (opt) => opt.value === orderNameToApply
                        )?.label
                      }
                      rightElement={<Button icon="caret-down" minimal />}
                    />
                  </Select>
                  <Select
                    items={directionOptions}
                    itemRenderer={(item, { handleClick }) => (
                      <MenuItem
                        key={item.value}
                        text={item.label}
                        onClick={handleClick}
                        disabled={item.value === orderDirection}
                      />
                    )}
                    onItemSelect={(item) =>
                      setOrderDirectionToApply(item.value as "ASC" | "DESC")
                    }
                    filterable={false}
                    fill
                    popoverProps={{
                      minimal: true,
                      matchTargetWidth: true,
                      captureDismiss: true,
                    }}
                  >
                    <InputGroup
                      className="cursor-pointer"
                      readOnly
                      value={
                        directionOptions.find(
                          (opt) => opt.value === orderDirectionToApply
                        )?.label
                      }
                      rightElement={<Button icon="caret-down" minimal />}
                    />
                  </Select>
                </div>
                <div className="flex gap-4 justify-end">
                  <Button minimal className={Classes.POPOVER2_DISMISS}>
                    Cancel
                  </Button>
                  <Button
                    intent={Intent.PRIMARY}
                    onClick={handleApplyClick}
                    className={Classes.POPOVER2_DISMISS}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </SortMenu>
          )}
        </div>

        <Button
          minimal
          rightIcon={
            view === "GRID" ? (
              <Icon icon="list" color="#5A829E" />
            ) : (
              <Icon icon="grid-view" color="#5A829E" />
            )
          }
          onClick={
            view === "GRID" ? () => setView("LIST") : () => setView("GRID")
          }
          className="ml-auto text-blue-6"
        >
          <span className="text-blue-6">
            {view === "GRID" ? "List view" : "Grid view"}
          </span>
        </Button>
      </div>
      {view === "LIST" && (
        <div className="w-[101%] px-12 ">
          <div className="flex w-full min-w-[478px] items-center justify-between border-b px-4 pb-2 font-light text-[#5a829e]">
            <div className="flex min-w-[150px] grow">Transaction name</div>
            {!hideClientMatterNumberColumn && (
              <div className="mr-2 w-1/3 flex-shrink-0">Client | C/M #</div>
            )}
            <div className="ml-auto flex w-1/3 flex-shrink-0 justify-between">
              <div className="w-1/3 break-words">Created on</div>
              <div className=" w-1/3 break-words">Created by</div>
              <div className="w-1/3 text-right">Actions</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
