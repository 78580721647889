import {
  Button,
  HTMLSelect,
  InputGroup,
  Intent,
  Spinner,
} from "@blueprintjs/core"
import styled from "@emotion/styled"
import { Topbar } from "components/nav"
import { AuthContext } from "helpers/contexts"

import { ChangeEvent, useContext, useEffect, useState } from "react"

const Search = styled(InputGroup)`
  min-width: 435px;

  .clear {
    margin: 7px;
    color: #5c7080;
  }
`

const Filter = styled(HTMLSelect)`
  margin-left: 10px;
  min-width: 78px;
`

type TransactionTopNavProps = {
  onSearchUpdate: (searchStr: string) => void
  onFilterSelect: (value: "active" | "archived" | "deactivated") => void
  searchLoading?: boolean
  hasDeactivatedTransactions?: boolean
}

export const TransactionTopNav = ({
  onSearchUpdate,
  searchLoading,
  onFilterSelect,
  hasDeactivatedTransactions,
}: TransactionTopNavProps) => {
  const [val, setVal] = useState("")

  useEffect(() => {
    onSearchUpdate(val)
  }, [val])

  const { user } = useContext(AuthContext)

  const handleFilterSelect = (evt: ChangeEvent<HTMLSelectElement>) =>
    onFilterSelect(evt.currentTarget.value)

  let rightEl = undefined

  if (val !== "") {
    rightEl = <Button minimal icon="cross" onClick={() => setVal("")} />
  }

  if (searchLoading) {
    rightEl = <Spinner size={17} intent={Intent.PRIMARY} />
  }

  return (
    <div
      className="fixed left-[459px] top-0 z-20"
      style={{ width: "calc(100% - 459px)" }}
    >
      <Topbar email={user?.email || ""}>
        <Search
          value={val}
          leftIcon="search"
          placeholder="Search by transaction name or details"
          onChange={({ target: { value } }) => setVal(value)}
          rightElement={rightEl}
          round
        />

        <Filter onChange={handleFilterSelect}>
          <option value="active">Active</option>
          <option value="archived">Archived</option>
          {hasDeactivatedTransactions && (
            <option value="deactivated">Deactivated</option>
          )}
        </Filter>
      </Topbar>
    </div>
  )
}
