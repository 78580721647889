import { useAppConfig } from "app/config"

import { Survey, NullSurvey } from "./survey"
import { User } from "models/User"

export function useTriggerSurvey() {
  let { config } = useAppConfig()

  async function triggerSurvey(user: User) {
    let survey = config?.ASK_NICELY_KEY ? new Survey(user) : NullSurvey

    await survey.trigger()
  }

  return { triggerSurvey }
}
